import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export const Newsletter = () => {
  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row className="align-items-center text-center">
          <Col lg={12} md={6} xl={5}>
            <h3>Get In Contact On Whatsapp</h3>
          </Col>
          <Col md={6} xl={7}>
            <a href="https://wa.me/+92197133056" target="_blank"  rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} size="4x"  style={{ color: "green" }} />
            </a>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
