import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/projects/1.png";
import projImg2 from "../assets/img/projects/2.png";
import projImg3 from "../assets/img/projects/3.png";
import projImg4 from "../assets/img/projects/4.png";
import projImg5 from "../assets/img/projects/5.png";
import projImg6 from "../assets/img/projects/6.png";
import projImg7 from "../assets/img/projects/7.png";
import projImg8 from "../assets/img/projects/8.png";
import projImg9 from "../assets/img/projects/9.png";
import projImg10 from "../assets/img/projects/10.png";
import projImg11 from "../assets/img/projects/11.png";
import projImg12 from "../assets/img/projects/12.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects1_6 = [
    {
      title: "E-commerce",
      description: "Inventory Managment",
      imgUrl: projImg1,
    },
    {
      title: "E-commerce",
      description: "Custom Shirt Design",
      imgUrl: projImg2,
    },
    {
      title: "Online Store",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Online Washing",
      description: "Multi Dashboard",
      imgUrl: projImg4,
    },
    {
      title: "Booking Managment",
      description: "Custom Dashbaord",
      imgUrl: projImg5,
    },
    {
      title: "Open AI",
      description: "AI Application",
      imgUrl: projImg6,
    },
  ];
  const projects7_12 = [
    {
      title: "Match Lawyer",
      description: "Chat & AI recommendation",
      imgUrl: projImg7,
    },
    {
      title: "Aggriculture Supplychain",
      description: "Blockchain Web App",
      imgUrl: projImg8,
    },
    {
      title: "Admin Dashboard",
      description: "Design & Development",
      imgUrl: projImg9,
    },
    {
      title: "User Managment CMS",
      description: "Dashboard",
      imgUrl: projImg10,
    },
    {
      title: "NFT Marketplace",
      description: "Blockchain App",
      imgUrl: projImg11,
    },
    {
      title: "Business Portfolio",
      description: "Mobile responsive",
      imgUrl: projImg12,
    },
  ];

  return (
    <section className="project" id="projects" >
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects1_6.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects7_12.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt=""></img>
    </section>
  )
}
